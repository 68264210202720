import { Link, StaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import '../../scss/footer/_footer.scss';

class Home extends Component {
  render() {
    const { contactData } = this.props;
    return (
      <>
        <section className="footer-content h-100">
          <Container fluid className="footer-content-containter pt-3 px-3">
            <Row>
              <Col md={4} className="text-center my-4">
                <p className="footer-sectionTitle">Navigation</p>

                <Link className="footer-nevigation-link" to="/meet-the-team">
                  Meet the Team
                </Link>
                <Link to="/our-services" className="footer-nevigation-link">
                  Our Services
                </Link>
                <Link to="/patient-info" className="footer-nevigation-link">
                  Patient Info
                </Link>
                <Link
                  to="/referring-offices"
                  className="footer-nevigation-link"
                >
                  Referring Offices
                </Link>
                <Link to="/contact" className="footer-nevigation-link">
                  Contact
                </Link>
              </Col>
              <Col md={4} className="text-center my-4">
                <p className="footer-sectionTitle ">Henrico Office</p>
                <a
                  href={
                    contactData[0].clinicInfo.henricoAppointment
                      .henricoAppointmentLink
                  }
                  className="footer-nevigation-link"
                  target="_blank"
                  rel="noreferrer"
                >
                  {
                    contactData[0].clinicInfo.henricoAppointment
                      .henricoAppointmentText
                  }
                </a>
                <a
                  href={
                    contactData[0].clinicInfo.henricoPatientPortal
                      .henricoPatientPortalLink
                  }
                  className="footer-nevigation-link"
                  target="_blank"
                  rel="noreferrer"
                >
                  {
                    contactData[0].clinicInfo.henricoPatientPortal
                      .henricoPatientPortalText
                  }
                </a>
                <a
                  href={
                    contactData[0].clinicInfo.henricoMambershipPlan
                      .henricoMambershipPlanLink
                  }
                  className="footer-nevigation-link"
                  target="_blank"
                  rel="noreferrer"
                >
                  {
                    contactData[0].clinicInfo.henricoMambershipPlan
                      .henricoMambershipPlanText
                  }
                </a>
                <a
                  href={
                    contactData[0].clinicInfo.henricoFinancingOptions
                      .henricoFinancingOptionsLink
                  }
                  className="footer-nevigation-link"
                  target="_blank"
                  rel="noreferrer"
                >
                  {
                    contactData[0].clinicInfo.henricoFinancingOptions
                      .henricoFinancingOptionsText
                  }
                </a>
              </Col>
              <Col md={4} className="text-center my-4">
                <p className="footer-sectionTitle">Midlothian Office</p>
                <a
                  href={
                    contactData[0].clinicInfo.midlothianAppointment
                      .midlothianAppointmentLink
                  }
                  className="footer-nevigation-link"
                  target="_blank"
                  rel="noreferrer"
                >
                  {
                    contactData[0].clinicInfo.midlothianAppointment
                      .midlothianAppointmentText
                  }
                </a>
                <a
                  href={
                    contactData[0].clinicInfo.midlothianPatientPortal
                      .midlothianPatientPortalLink
                  }
                  className="footer-nevigation-link"
                  target="_blank"
                  rel="noreferrer"
                >
                  {
                    contactData[0].clinicInfo.midlothianPatientPortal
                      .midlothianPatientPortalText
                  }
                </a>
                <a
                  href={
                    contactData[0].clinicInfo.midlothianMambershipPlan
                      .midlothianMambershipPlanLink
                  }
                  className="footer-nevigation-link"
                  target="_blank"
                  rel="noreferrer"
                >
                  {
                    contactData[0].clinicInfo.midlothianMambershipPlan
                      .midlothianMambershipPlanText
                  }
                </a>
                <a
                  href={
                    contactData[0].clinicInfo.midlothianFinancingOptions
                      .midlothianFinancingOptionsLink
                  }
                  className="footer-nevigation-link"
                  target="_blank"
                  rel="noreferrer"
                >
                  {
                    contactData[0].clinicInfo.midlothianFinancingOptions
                      .midlothianFinancingOptionsText
                  }
                </a>
              </Col>
            </Row>
            <Row>
              <Col md={12} className="text-center align-items-center">
                <p className="footer-copright ">
                  Copyright © 2022 Tulips Periodontics &amp; Dental Implants.
                  All rights reserved.
                </p>
              </Col>
            </Row>
          </Container>
        </section>
      </>
    );
  }
}

// export default Home;

// eslint-disable-next-line react/display-name
export default () => (
  <StaticQuery
    query={graphql`
      query FooterQuery {
        allContactJson {
          nodes {
            clinicInfo {
              henricoAppointment {
                henricoAppointmentLink
                henricoAppointmentText
              }
              midlothianAppointment {
                midlothianAppointmentLink
                midlothianAppointmentText
              }
              henricoPatientPortal {
                henricoPatientPortalLink
                henricoPatientPortalText
              }
              midlothianPatientPortal {
                midlothianPatientPortalLink
                midlothianPatientPortalText
              }
              henricoMambershipPlan {
                henricoMambershipPlanLink
                henricoMambershipPlanText
              }
              midlothianMambershipPlan {
                midlothianMambershipPlanLink
                midlothianMambershipPlanText
              }
              henricoFinancingOptions {
                henricoFinancingOptionsLink
                henricoFinancingOptionsText
              }
              midlothianFinancingOptions {
                midlothianFinancingOptionsLink
                midlothianFinancingOptionsText
              }
            }
          }
        }
      }
    `}
    render={(data) => <Home contactData={data.allContactJson.nodes} />}
  />
);

Home.propTypes = {
  contactData: PropTypes.array.isRequired,
};
