import React, { Component } from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import { Link, StaticQuery, graphql } from 'gatsby';
import { FaBars, FaTimes } from 'react-icons/fa';
import PropTypes from 'prop-types';
import '../../scss/header/_navigation.scss';

class Navigation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      navClass: 'navigation-transparent',
      logoSrc: props.creamLogo,
      burgerIcon: false,
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
    window.addEventListener('load', this.handleResize);
    window.addEventListener('scroll', this.listenScrollEvent);
  }

  componentWillUnmount() {
    window.addEventListener('resize', this.handleResize);
  }

  listenScrollEvent = () => {
    const { creamLogo, greenLogo } = this.props;
    if (window.scrollY > 50 || window.innerWidth <= 991) {
      this.setState({ navClass: 'navigation-solid', logoSrc: greenLogo });
    } else {
      this.setState({ navClass: 'navigation-transparent', logoSrc: creamLogo });
    }
  };

  handleClick = () => {
    this.setState((prevState) => ({
      burgerIcon: !prevState.burgerIcon,
    }));
  };

  handleResize = () => {
    const { creamLogo, greenLogo } = this.props;
    if (window.innerWidth <= 991) {
      this.setState({ navClass: 'navigation-solid', logoSrc: greenLogo });
    } else {
      this.setState({ navClass: 'navigation-transparent', logoSrc: creamLogo });
    }
  };

  render() {
    const { navClass, logoSrc, burgerIcon } = this.state;

    return (
      <>
        <Navbar
          className={`navigation-content ${navClass}`}
          bg="none"
          variant="none"
          fixed="top"
          expand="lg"
        >
          <Container fluid>
            <Navbar.Brand href="/">
              <img
                src={logoSrc}
                alt="Tulips Periodontics &amp; Dental Implants - Logo"
              />
            </Navbar.Brand>
            <Navbar.Toggle
              aria-controls="responsive-navbar-nav"
              onClick={this.handleClick}
            >
              {burgerIcon ? (
                <FaTimes className="navigation-icon-bars" />
              ) : (
                <FaBars className="navigation-icon-bars" />
              )}
            </Navbar.Toggle>
            <Navbar.Collapse className="navigation-collapse">
              <Nav className="navbar-nav ml-auto">
                <Link
                  className="navigation-links mx-2"
                  to="/meet-the-team"
                  activeClassName="active"
                >
                  Meet the Team
                </Link>
                <Link
                  className="navigation-links mx-2"
                  activeClassName="active"
                  to="/our-services"
                >
                  Our Services
                </Link>
                <Link
                  className="navigation-links mx-2"
                  activeClassName="active"
                  to="/patient-info"
                >
                  Patient Info
                </Link>
                <Link
                  className="navigation-links mx-2"
                  activeClassName="active"
                  to="/referring-offices"
                >
                  Referring Offices
                </Link>
                <Link
                  className="navigation-links navigation-contact mx-2 px-2"
                  activeClassName="active"
                  to="/contact"
                >
                  Contact
                </Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </>
    );
  }
}

// export default Navigation;

// eslint-disable-next-line react/display-name
export default () => (
  <StaticQuery
    query={graphql`
      query NavigationQuery {
        greenLogo: allFile(
          filter: { relativePath: { eq: "logos/green-logo.svg" } }
        ) {
          nodes {
            id
            publicURL
          }
        }

        creamLogo: allFile(
          filter: { relativePath: { eq: "logos/cream-logo.svg" } }
        ) {
          nodes {
            id
            publicURL
          }
        }
      }
    `}
    render={(data) => (
      <Navigation
        greenLogo={data.greenLogo.nodes[0].publicURL}
        creamLogo={data.creamLogo.nodes[0].publicURL}
      />
    )}
  />
);

Navigation.propTypes = {
  greenLogo: PropTypes.string.isRequired,
  creamLogo: PropTypes.string.isRequired,
};
