/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import Navigation from '../components/Header/Navigation';
import '../scss/_index.scss';
// import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
// import Header from '../components/Header/Header';

const Layout = ({ children }) => (
  <>
    <div>
      {/* <Header /> */}
      <Navigation />
      <main>{children}</main>
      <Footer />
    </div>
  </>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
